import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthProvider';

//  auth = true : if want redirect when user is login
//  auth = false : if want redirect when use is not login
const PrivateRoute = ({ children, auth = false, redirect }) => {
  const { loading, user } = useAuth();
  if (loading) return <LinearProgress />;

  let isAuth = false;
  if (user) {
    isAuth = true;
  }

  return loading === false && isAuth !== auth ? (
    children
  ) : (
    <Navigate to={redirect} />
  );
};

export default PrivateRoute;
