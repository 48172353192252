import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import logDevelopment from 'src/utils/log-development';
import AUTH_API from 'src/api/auth';
import AccessControl from 'src/utils/access-control';

const AuthContext = React.createContext({
  user: null,
  loading: true,
  login: () => {},
  logout: () => {},
  checkPermission: () => {},
  getMe: () => {},
});

const accessControl = new AccessControl();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    try {
      getMe();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    try {
      if (user?.roles) {
        grantPermissions(user.roles);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // get own user info
  const getMe = async () => {
    try {
      const response = await AUTH_API.getMe();
      setUser(response?.data?.userWorkspace ?? null);
      return response;
    } catch (err) {
      console.error('getMe', err);
    } finally {
      setLoading(false);
    }
  };

  const login = (newUser) => {
    return new Promise(async (resolve, reject) => {
      try {
        await AUTH_API.login(newUser);
        const response = await getMe();
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const logout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AUTH_API.logout();
        setUser(null);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const forgotPassword = async (payload) => {
    try {
      const { data } = await AUTH_API.forgotPassword(payload);
      enqueueSnackbar(data.message);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const resetPassword = async (payload) => {
    try {
      await AUTH_API.resetPassword(payload);
      navigate('/login');
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  // grantPermission to accessControl
  const grantPermissions = (roles) => {
    roles.forEach((role) => {
      role.id.features.forEach((feature) => {
        feature.rolePermission.forEach((permission) => {
          if (permission.boolean) {
            accessControl.grantPermission(
              permission.permissionName,
              feature.featureName,
            );
          }
        });
      });
    });
    logDevelopment('Granted Permission\n', accessControl.getPermission());
  };

  const checkPermission = (execute, feature) => {
    return accessControl.checkPermission(execute, feature);
  };

  const value = {
    user,
    loading,
    login,
    logout,
    forgotPassword,
    resetPassword,
    checkPermission,
    getMe,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;
