import login from './login.json';
import common from './common.json';
import authenticate from './authenticate.json';
import user from './user.json';
import rolePermission from './role-permission.json';
import department from './department.json';
import participant from './participant.json';
import participantRegistration from './participant-registration.json';
import cityAndProvince from './city-and-province.json';

export const TRANSLATIONS_EN = {
  ...login,
  ...authenticate,
  ...user,
  ...common,
  ...rolePermission,
  ...department,
  ...participantRegistration,
  ...cityAndProvince,
  ...participant,
};
