import React from 'react';
import { Avatar, Grid } from '@mui/material';

const Logo = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        height: '64px',
      }}
      alignItems='center'
      justifyContent='center'
    >
      <Grid item sx={{ borderRadius: '5px' }}>
        <Avatar
          variant='square'
          src='/logo.png'
          sx={{
            width: '100%',
            '& img': { objectFit: 'contain' },
            padding: '5px',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Logo;
