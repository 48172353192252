import React from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@mui/system';

import Logo from '../logo';
import Menu from './menu';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': { ...openedMixin(theme), backgroundColor: theme.palette.primary.main, borderRight: 'none' },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': { ...closedMixin(theme), backgroundColor: theme.palette.primary.main, borderRight: 'none' },
    }),

  }),
);

const DrawerComponent = ({ open }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Logo />
      <Menu open={open} />
    </Drawer>
  );
};

export default DrawerComponent;
