import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import globalStyle from './global';
import khmerOSsiemreap from '../fonts/KhmerOS_siemreap.ttf';

export const primary = {
  lighter: '#02b9ca',
  light: '#02a2b1',
  main: '#017681',
  highlight: '#03e8fc',
  dark: '#01454c',
  darker: '#012e32',
};

let theme = createTheme({
  typography: {
    fontFamily: [
      'Khmer OS Siemreap',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary,
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#D73027',
    },
    drawer: {
      background: primary.main,
      hover: primary.light,
      selected: primary.darker,
      subSelected: primary.dark,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Khmer OS Siemreap";
          src: url(${khmerOSsiemreap}) format("truetype");
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 2,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
        elevation2: {
          boxShadow:
            '1px 1px 18px 1px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
      },
    },
  },
});

//overwrite globalStyle
theme.components.MuiCssBaseline.styleOverrides = globalStyle(theme);

theme = responsiveFontSizes(theme);

export default theme;
